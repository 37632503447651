<template>
  <div class="category-main">
    <Breadcrumb :items="breadcrumb" />
    <ebsn-style :target="category" path="category_advanced.CSS_STYLE" />
    <category-block
      :target="category"
      :category-id="category.categoryId"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container class="category-header">
      <ebsn-meta
        :target="category"
        path="category_info.TITLE"
        :default-value="category.name"
        tag="h1"
        :style="{ color: $ebsn.meta(category, 'category_info.COLOR') }"
      ></ebsn-meta>

      <v-card outlined>
        <v-img
          :src="$ebsn.meta(category, 'category_info.HEADER_IMAGE')"
          alt=""
          contain
          onerror="this.onerror=null;this.src='/no-icon.png'"
          width="100%"
        >
          <div class="category-description">
            <ebsn-meta
              class="SUBTITLE"
              :target="category"
              path="category_info.SUBTITLE"
              tag="h3"
              @click="clicked"
            ></ebsn-meta>
            <ebsn-meta
              class="description"
              :target="category"
              path="category_info.DESCRIPTION"
              tag="p"
              @click="clicked"
            ></ebsn-meta>
          </div>
        </v-img>
      </v-card>
    </v-container>
    <v-container>
      <CategoryMainSlider
        :categories="category.children"
        :showArrows="false"
        :showBullets="true"
        :key="category.categoryId"
        paginationClass="subcategory"
      />
    </v-container>
    <category-block
      :target="category"
      :category-id="category.categoryId"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      :category-id="category.categoryId"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_TEXT"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.category-main {
  .center-img {
    margin-right: auto;
    margin-left: auto;
  }
  .category-header {
    h3 {
      line-height: 0.9;
      line-height: 1.2;
    }
    .category-description {
      position: absolute;
      right: 20px;
      background: #fff;
      top: 40px;
      width: 45%;
      padding: 30px;
    }
  }
}
</style>
<script>
// @ is an alias to /src
// import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CategoryMainSlider from "@/components/category/CategoryMainSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";

import get from "lodash/get";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      },
      count: -1
    };
  },
  components: {
    // CategoryTitle,
    CategoryMainSlider,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  methods: {
    updateCount(count) {
      this.count = count;
    },
    clicked() {}
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.category ? this.category.name : ""
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      "Categoria: " + this.category ? this.category.name : ""
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "cooptrentino.it"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
